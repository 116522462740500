<template>
  <div>
        <div class="Search_main">
                 <img class="Search_main_img" src="@/assets/imagel/repsearch.png" alt="">
        </div>
             <div class="hospotal_name">
               <van-cell title="福 贡 县 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF FUGONG</span>
                 </template>
               </van-cell>
               <!-- <van-cell title="江 城 县 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF JIANGCHENG</span>
                 </template>
               </van-cell> -->
               <!-- <van-cell title="镇 沅 县 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF ZHENYUAN</span>
                 </template>
               </van-cell> -->
               <!-- <van-cell title="澜 沧 县 第 一 人 民 医 院">
                 <template slot="label">
                   <span style="color:white;">THE PEOPLE'S HOSPITAL OF LanCang</span>
                 </template>
               </van-cell> -->
             </div>

        <van-grid :column-num="4">
          <van-grid-item>
            <van-icon slot="icon" class="iconfont" class-prefix='icon' name="guahao" color="#57c4b7" size="48" @click="onclicklprep"/>
            <span slot="text" style="color:#57c4b7; font-size:14px;">检验报告</span>
          </van-grid-item>
          <van-grid-item>
            <van-icon slot="icon" class="iconfont" class-prefix='icon' name="jiaofei" color="#57c4b7" size="48" @click="onclickvprep"/>
            <span slot="text" style="color:#57c4b7; font-size:14px;">影像报告</span>
          </van-grid-item>
        </van-grid>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>

</template>
<script>
import "@/assets/iconfont/search/iconfont.css";

export default {
  data() {
    return {
      zhuyaunIcon:require("../../assets/zhuyuan.png")
    };
  },

  created() {
     
    },
  methods:{
      onclicklprep() {
        this.$router.replace({   //检验报告
          path: '/RpCardL',
        }).catch(err => {
                console.log(err);
            });
      },
      onclickvprep() {
          this.$router.replace({
            path: '/VpCardL',   //影像报告
          }).catch(err => {
                console.log(err);
            });
      }
  }
};
</script>

<style lang="less" scoped>
// .van-grid{
//   padding: 2%;
// }

.Search_main_img{
  width: 100%;
}
.hospotal_name{
    position: absolute;
    top: 0;
    // margin: 10px;
    .van-cell{
      margin: 0;
      padding: 5px;
      color: white;
      background:rgba(0,0,0,0);
    }
}
</style>
